import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getAccessToken } from "../../services/storageService"


export default function Protected() {
    const accessToken = getAccessToken();

    return (
        accessToken ? <Outlet /> : <Navigate to="/" />
    )
}