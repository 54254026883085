import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { deleteAccessToken, deleteRefreshToken, setAccessToken, setRefreshToken } from "../services/storageService";
import { axiosParams } from "./axiosParams";

export const axiosRefresh = axios.create(axiosParams);

const handleRefreshFailed = () =>
{
    deleteAccessToken();
    deleteRefreshToken();
    window.location.href = "/";
};

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosRefresh, handleRefreshFailed, { statusCodes: [400, 401, 500]});