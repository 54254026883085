import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { refreshToken } from "../services/authService";
import { setAccessToken, setRefreshToken } from "../services/storageService";
import { axiosParams } from "./axiosParams";

export const axiosPublic = axios.create(axiosParams);

const handleAuthError = (failedRequest) =>
        refreshToken()
        .then((res) => {
            setAccessToken(res.data.accessToken);
            setRefreshToken(res.data.refreshToken);
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + res.data.accessToken;
            return Promise.resolve();
        });

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosPublic, handleAuthError);