import * as React from 'react';
import {
CssBaseline,
TextField,
Box,
Container, 
createTheme, 
ThemeProvider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useLoginForm from '../../hooks/useLoginForm';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/agmo.png';
import { Navigate } from 'react-router-dom';
import { getAccessToken, setAccessToken, setRefreshToken } from '../../services/storageService';
import { login } from '../../services/authService';

const theme = createTheme();

export const loginModel = () => ({
  email: '',
  password: '',
})

export default function Login() {
  const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange
    } = useLoginForm(loginModel);

    const handleSubmit = (event) => {
      event.preventDefault();
      if (!loading) {
        setLoading(true);
        if (validate()){
          login(values)
          .then(res => {
            setAccessToken(res.data.accessToken);
            setRefreshToken(res.data.refreshToken);
            navigate('/dashboard');
          })
          .catch(err => {
            console.log(err.response.data.message);
            setErrors({password: err.response.data.message})
            setLoading(false);
          })
        }
        else{
          setLoading(false);
        }
      }
    };

    const validate = () => {
      let temp = {}
      temp.email = (/\S+@\S+\.\S+/).test(values.email) ? "" : "Invalid email."
      temp.password = values.password != "" ? "" : "Invalid password."
      setErrors(temp)
      // setLoading(false);
      return Object.values(temp).every(x => x == "")
    }

  if(getAccessToken() !== null) {
    return <Navigate to={{pathname: '/dashboard'}} />
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={ logo } alt="agmo.png" height={156}/>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              autoComplete="email"
              autoFocus
              {...(errors.email && { error: true, helperText: errors.email })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              value={values.password}
              onChange={handleInputChange}
              type="password"
              autoComplete="current-password"
              {...(errors.password && { error: true, helperText: errors.password })}
            />
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}