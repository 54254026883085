import { axiosPublic } from "../common/axiosPublic";
import { axiosRefresh } from "../common/axiosRefresh";
import { getAccessToken, getDeviceId, getRefreshToken } from "./storageService";

const API_ENDPOINT = {
    login: "api/authentication/login",
    logout: "api/authentication/logout",
    refreshToken: "api/authentication/refreshToken"
}

export function login(loginRequestDto) {
    return axiosPublic.post(API_ENDPOINT.login, {
        username: loginRequestDto.email,
        password: loginRequestDto.password,
        device: {
            deviceId: getDeviceId(),
            deviceType: 0,
            pushNotificationToken: '',
            guiVersion: '1.0.0'
        }});
}

export function logout() {
    return axiosPublic.post(API_ENDPOINT.logout, {}, getAccessTokenHeader());
}

export function refreshToken() {
    return axiosRefresh.post(API_ENDPOINT.refreshToken, {}, getRefreshTokenHeader());
}

export function getAccessTokenHeader() {
    return { headers: {
        Authorization: 'Bearer ' + getAccessToken()
    }};
}

function getRefreshTokenHeader() {
    return { headers: {
        Authorization: 'Bearer ' + getRefreshToken()
    }};
}