import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Bars } from 'react-loader-spinner'
import {
  Box,
  Button,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  MenuList,
  Modal,
  Menu
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { logout } from '../../services/authService';
import { useNavigate } from 'react-router-dom';
import { getUserReport } from '../../services/reportService';
import { deleteAccessToken, deleteRefreshToken } from '../../services/storageService';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { KeyboardArrowDown } from '@mui/icons-material';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Dashboard() {
  const navigate = useNavigate();
  const [buttonText, setButtonText] = React.useState("Export Customer Data");
  const [downloadButtonVisibility, setButtonVisibility] = React.useState(false);
  const [reportLoaded, setReportLoaded] = React.useState(false);
  const [powerBiReportUrl, setPowerBiReportUrl] = React.useState();
  const [reports, setReports] = React.useState([]);
  const [noSubscriptionModalOpen, setNoSubscriptionModalOpen] = React.useState(false);

  var currentReport;
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleIframeLoad = () => setReportLoaded(true);
  const handleNoSubscriptionModalClose = () => {
    console.log('closing');
    setNoSubscriptionModalOpen(false);
  };

  const username = localStorage.getItem("username");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout()
      .then(res => {
        deleteAccessToken();
        deleteRefreshToken();
        navigate('/');
      })
  }

  const handleDownloadCSV = () => {
    window.open(currentReport.url, '_blank', 'noopener,noreferrer');
  }

  const handleOnMenuItemClick = (event, report, popupState) => {
    setPowerBiReportUrl(report.reportDetails[0].url);
    popupState.close();
  }

  React.useEffect(() => {
    const getReportForCurrentUser = async () => {
      getUserReport()
        .then(res => {
          setReportLoaded(true);
          if(res.data.length > 0) {
            setReports(res.data);
            setPowerBiReportUrl(res.data[0].reportDetails[0].url);
          } else {
            setNoSubscriptionModalOpen(true);
          }
        })
    };
    getReportForCurrentUser();
  }, []);



  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="inherit" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography noWrap component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          {downloadButtonVisibility ? <Button id="app-button" color="inherit" onClick={handleDownloadCSV} >{buttonText}</Button> : null}
          {reports.length > 1 ?
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color="inherit" {...bindTrigger(popupState)} endIcon={<KeyboardArrowDown />}>
                    Reports
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    {
                      reports.map((report, index) => (
                        <MenuItem key={report.id} onClick={(e) => handleOnMenuItemClick(e, report, popupState)}>{report.name}</MenuItem>
                      ))
                    }
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
            : <Modal
              open={noSubscriptionModalOpen}
              onClose={handleNoSubscriptionModalClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{
                width: 600,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                border: '1px solid #000',
                borderRadius: 2,
                boxShadow: 24,
                pt: 2,
                px: 4,
                pb: 3,
              }}>
                <h2 id="parent-modal-title" style={{ paddingBottom: 20 }} >Subscription Needed</h2>
                <p id="parent-modal-">
                  <div>You have no active dashboard subscription.</div>
                  <div style={{ paddingBottom: 30 }}>Please contact <a href="mailto: sales@agmostudio.com">sales@agmostudio.com</a>.</div>
                </p>
              </Box>
            </Modal>}
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Typography component="div" sx={{ marginRight: 'auto', marginLeft: 1 }}>
            Menu
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Dashboard'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton to={'/' + text}
                selected={selectedIndex === index}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>

          ))}
        </List>
      </Drawer>
      <Main open={noSubscriptionModalOpen}>
        <DrawerHeader />
        {!reportLoaded ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', }} >
          <Bars height="80" width="80" radius="9" color="#1976d2" ariaLabel="loading" wrapperStyle />
        </div>
          : null}
        <iframe onLoad={handleIframeLoad}  title="PowerBI" src={powerBiReportUrl} frameborder="0" allowFullScreen="true"></iframe>
      </Main>
    </Box>
  );
}
