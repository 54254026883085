import { v1 as uuidv1 } from 'uuid';

export function getAccessToken() {
    return localStorage.getItem("accessToken");
}

export function setAccessToken(accessToken) {
    localStorage.setItem("accessToken", accessToken);
}

export function deleteAccessToken() {
    localStorage.removeItem("accessToken");
}

export function getRefreshToken() {
    return localStorage.getItem("refreshToken");
}

export function setRefreshToken(refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
}

export function deleteRefreshToken() {
    localStorage.removeItem("refreshToken");
}

export function getDeviceId() {
    let deviceId = localStorage.getItem("deviceId");

    if(deviceId) {
        return deviceId;
    }

    deviceId = uuidv1();
    localStorage.setItem("deviceId", deviceId);
    return deviceId;
}